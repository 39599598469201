<template>
  <div>
    <el-header class="reaHead">
      <div class="index-one" @click="homeClick">
        <el-image :src="require('@/assets/logo.png')" alt=""></el-image>
        <div class="index-title">会务管理系统</div>
      </div>
    </el-header>
    <div style="margin-top: 20px">
      <div class="topTitle">
        找回登录密码
      </div>
      <div style="width: 40%;height: 1px;border-top: 1.5px solid #ccc;margin: 0 auto;margin-bottom: 10px;margin-top: 10px;"></div>
    </div>
    <div class="forget-form">
      <el-form :model="forgetYzmlForm" ref="forgetYzmlForm" style="margin: auto;" :rules="forgetYzmrules" hide-required-asterisk label-position='right' label-width="90px">
        <el-form-item prop="pwd" label="新密码：" class="yzm">
          <el-input v-model="forgetYzmlForm.pwd" type="password" class="yzm-pass" auto-complete="off" placeholder="请输入新密码" clearable></el-input>
        </el-form-item>
        <el-form-item prop="newPwd" label="确认密码：" class="yzm">
          <el-input v-model="forgetYzmlForm.newPwd" type="password" class="yzm-pass" auto-complete="off" placeholder="请确认新密码" clearable></el-input>
        </el-form-item>
        <el-button class="next-button" @click="confirmPwd">提交</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import _qs from "qs";
export default {
  data() {
    return {
      forgetYzmrules: {
        pwd: [
          { required: true, message: "请填写新密码", trigger: "blur" },
        ],
        newPwd: [
          { required: true, message: "请填写新密码", trigger: "blur" },
        ],
      },
      //
      forgetYzmlForm: {
        pwd: "",
        newPwd: "",
      },
    };
  },
  created() {
    console.log(window.sessionStorage.getItem("forgetPhone"))
  },
  methods: {
    homeClick(){
      this.$router.push("/login");
    },
    //
    async confirmPwd() {
      this.$refs.forgetYzmlForm.validate(async (valid) => {
        if(valid){
          if (this.forgetYzmlForm.pwd != this.forgetYzmlForm.newPwd) {
            this.$message.error("两次输入不一致，请重新输入。");
            return false;
          }


          let data = _qs.stringify({
            phone: window.sessionStorage.getItem("forgetPhone"),
            passWord: this.forgetYzmlForm.newPwd,
          });
          let {data:res} = await this.$axios.retrievePassword(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 500) {
            this.$message({
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            window.sessionStorage.clear();
            window.localStorage.clear();
            this.$router.push("/login");
          }

        } else {
          return false;
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>

.topTitle{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.yzm {
  width: 100%;
  //display: flex;
  flex-direction: row;
  .yzm-pass {
    width: 100%;
  }
}
.next-button {
  width: 100%;
  position: relative;
  background-image: linear-gradient(to right, #00c3ff, #00abff, #0095ff);
  color: white;
  font-weight: bolder;
}
.forget-form {
  width: 38%;
  height: 300px;
  background: white;
  display: flex;
  padding: 10px 20px;
  box-shadow: 0 0 16px #ccc;
  //border-radius: 20px 0 20px 20px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
/deep/ .reaHead {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  //background: rgba(0, 0, 0, 0.6);
  background:#1b63c1 !important;
  color: white;
  justify-content: space-between;
  .index-title {
    font-size: 30px;
  }
  .index-one {
    display: flex;
    position: relative;
    align-items: center;
  }
  .index-one:hover {
    cursor: pointer;
  }

  .el-image__inner {
    width: 55%;
  }
  /deep/ .el-input--prefix .el-input__inner {
    border-radius: 20px;
  }
}
</style>
